import React from 'react';

const SearchBox = ({searchfield, searchChange}) => {
    return (
        <div className ="search">
        <input  class="searchTerm" placeholder="What are you looking for?" type='search' 
        
        onChange={searchChange}
        />
        <button type="submit" class="searchButton">
        <i class="fa fa-search"></i>
     </button>
     <br/> 
     <label className='transbox'>
  <input type="checkbox" name="searchtranslation" value="false" onChange={searchChange} />
  <span>Search translations</span>
</label>


        </div>
    )
}

export default SearchBox;